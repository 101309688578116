/*
0-600px    Phone
600-900px  Tablet portrait mode
900-1200px Tablet landscape mode
1200-1800px Is where are normal style applay
1800 +     Big desktop





$breakpoint argument choices:
-phone
-tab-port
-tab-land
-big-desktop

Oreder: Base + typgraphy > general layout + grid > page layout > components

1em = 16px
*/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit; }

::selection {
  color: #fff;
  background: #fc0000; }

/* Track */
/* Handle */
/* Handle on hover */
html {
  font-size: 62.5%; }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 1.7;
  font-size: 1.6rem; }

.p-b-4 {
  padding-bottom: 4rem; }

.m-b-2 {
  margin-bottom: 2rem; }

.header-center {
  text-align: center; }

.highlight-text {
  color: #fc0000; }

.bb-primary-color {
  border-bottom: 5px solid #fc0000; }

.bb-dark-color-2 {
  border-bottom: 2px solid #fc0000; }

.bb-white-color {
  border-bottom: 5px solid #fff; }

h1 {
  font-size: 4.5rem;
  margin: 0 0 3rem 0; }

h2 {
  text-transform: uppercase;
  display: inline-block;
  font-size: 3.5rem;
  margin: 6rem 0;
  line-height: 1; }
  @media only screen and (max-width: 56.25em) {
    h2 {
      margin: 4rem 0; } }
  @media only screen and (max-width: 37.5em) {
    h2 {
      margin: 3rem 0; } }

h3 {
  font-size: 2.5rem; }

h4 .font-bootstrap {
  font-size: 1.5rem;
  background: #252323;
  padding: 0.3rem 0.4rem 0 0.4rem;
  color: #fff; }

.container {
  max-width: 1170px;
  max-width: 117rem;
  margin: 0 auto;
  overflow: hidden; }
  .container-projects {
    padding-bottom: 10rem; }

.btn {
  display: inline-block;
  font-size: 2rem;
  color: #fff;
  background: rgba(255, 0, 0, 0.4);
  padding: 0.5rem 2rem;
  border-top: 3px solid #fff;
  text-decoration: none;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }
  .btn:hover {
    border: transparent;
    border-top: 3px solid #fc0000;
    background: #252323; }

.social-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 4rem; }
  @media only screen and (max-width: 56.25em) {
    .social-icon {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; } }
  .social-icon a {
    font-size: 2.8rem;
    background: transparent;
    color: #fff;
    z-index: 1;
    margin: 0 2rem;
    -webkit-transition: color 0.2s ease-in;
    transition: color 0.2s ease-in; }
    .social-icon a i {
      padding: 1.2rem; }
    .social-icon a:hover {
      color: #ccc; }

a[data-tool-tip] {
  position: relative; }

a[data-tool-tip]::after {
  content: attr(data-tool-tip);
  width: 8rem;
  display: block;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-top: 3px solid red;
  position: absolute;
  padding: 0.2rem 1rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  top: 0;
  left: -1.5rem;
  white-space: nowrap;
  text-align: center;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all ease-out 0.3s;
  transition: all ease-out 0.3s; }

a[data-tool-tip]:hover::after {
  -webkit-transform: scale(1);
  transform: scale(1);
  top: -2.5rem; }

#tool-tip-email::after {
  text-transform: lowercase;
  width: 19rem;
  top: 1.5rem;
  left: -7rem; }

#tool-tip-email:hover::after {
  left: -20rem;
  top: 1.5rem; }

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: #000;
  background: transparent;
  color: #fff;
  z-index: 20;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  .navbar-inner {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .navbar-inner h3 {
      font-size: 3rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .navbar-inner h3 .logo-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
      .navbar-inner h3 .navbar-logo {
        height: 5rem;
        margin: 1rem 1.5rem 1rem 0; }
        @media only screen and (max-width: 75em) {
          .navbar-inner h3 .navbar-logo {
            margin-left: 2rem; } }
      .navbar-inner h3 .fadeIn {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-animation: fadeIn 1s forwards;
        animation: fadeIn 1s forwards;
        -webkit-animation-delay: 1s;
        animation-delay: 1s; }
    .navbar-inner .nav ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      list-style: none; }
      @media only screen and (max-width: 75em) {
        .navbar-inner .nav ul {
          margin-right: 2rem; } }
      .navbar-inner .nav ul li {
        display: inline-block; }
        .navbar-inner .nav ul li a {
          text-decoration: none;
          color: #fff;
          padding: 0.3rem;
          margin: 0 2rem;
          font-size: 2rem; }
          .navbar-inner .nav ul li a:last-child {
            margin-right: 0; }
          .navbar-inner .nav ul li a:hover {
            border-top: 0.3rem solid #fc0000;
            color: #ccc; }

@media only screen and (max-width: 37.5em) {
  .navbar-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
    .navbar-inner .nav {
      width: 100%;
      background: transparent; }
      .navbar-inner .nav ul {
        width: 100%;
        margin-right: 0;
        display: grid;
        grid-template-columns: repeat(5, 1fr); }
        .navbar-inner .nav ul li {
          text-align: center;
          padding: 0.5rem 0;
          border-right: 1px solid #333; }
          .navbar-inner .nav ul li a {
            margin: 0; }
        .navbar-inner .nav ul li:last-child {
          border-right: 0; } }

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.showcase {
  background: #fc0000;
  height: 100vh;
  color: #fff;
  position: relative; }
  .showcase::before {
    content: '';
    background-color: #fc0000;
    background: url("../Img/header-computer.jpg") no-repeat center bottom/cover;
    background-attachment: fixed;
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1; }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), (min-resolution: 192dpi) and (min-width: 37.5em), (min-width: 125em) {
      .showcase::before {
        background: url("../Img/header-computer.jpg") no-repeat center top/cover; } }
  .showcase-container {
    height: 100%;
    width: 100%;
    position: relative; }
  .showcase-content {
    width: 100%;
    height: 100%; }
    .showcase-content-text {
      position: absolute;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      -ms-flex-item-align: end;
      align-self: flex-end;
      text-transform: uppercase;
      z-index: 1; }
      @media only screen and (max-width: 75em) {
        .showcase-content-text {
          margin: 0 4rem; } }
      @media only screen and (max-width: 37.5em) {
        .showcase-content-text {
          width: 100%;
          text-align: center;
          margin: 0 auto; } }
      @media (max-height: 21em) {
        .showcase-content-text h3,
        .showcase-content-text h1 {
          font-size: 2rem; } }
      .showcase-content-text h3 {
        margin-bottom: 1.5rem; }
      .showcase-content-text h1 {
        border-right: 0.2rem solid #252323;
        padding: 0;
        line-height: 1.1; }
      @media only screen and (max-width: 37.5em) {
        .showcase-content-text .txt-type {
          display: none; } }
      .showcase-content-text .txt-type-phone {
        display: none; }
        @media only screen and (max-width: 37.5em) {
          .showcase-content-text .txt-type-phone {
            display: block; } }
    .showcase-content .social-icon {
      position: absolute;
      top: 85%;
      right: 0; }

.section-skills {
  background-color: #fff; }
  .section-skills-boxes {
    text-align: center;
    margin-bottom: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row;
    flex-flow: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .section-skills-boxes .box {
      width: 30rem; }
      .section-skills-boxes .box .circle-wrap {
        background: #8b0000;
        margin: 2rem auto;
        width: 20rem;
        height: 20rem;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-transition: opacity 1s ease-in;
        transition: opacity 1s ease-in;
        opacity: 0; }
        .section-skills-boxes .box .circle-wrap:hover .inside-circle {
          -webkit-transform: scale(1.05);
          transform: scale(1.05); }
        .section-skills-boxes .box .circle-wrap .inside-circle {
          background: #fff;
          width: 18rem;
          height: 18rem;
          border-radius: 50%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-transition: all ease-in 0.2s;
          transition: all ease-in 0.2s; }
          .section-skills-boxes .box .circle-wrap .inside-circle .html {
            max-height: 10rem; }
          .section-skills-boxes .box .circle-wrap .inside-circle .css3 img {
            max-height: 11rem; }
          .section-skills-boxes .box .circle-wrap .inside-circle .sass {
            max-height: 9rem; }
          .section-skills-boxes .box .circle-wrap .inside-circle .javaScript {
            max-height: 8rem; }
          .section-skills-boxes .box .circle-wrap .inside-circle .react {
            max-height: 9rem; }
          .section-skills-boxes .box .circle-wrap .inside-circle .redux {
            max-height: 9rem; }
      .section-skills-boxes .box .show {
        opacity: 1; }
  @media only screen and (max-width: 75em) {
    .section-skills-other {
      margin: 0 2rem; } }
  .section-skills-text {
    border-left: 5px solid #fc0000;
    padding-left: 0.5rem;
    margin-bottom: 5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row;
    flex-flow: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .section-skills-text img {
      max-height: 5rem;
      margin: 2rem; }
    .section-skills-text .mongoose {
      min-height: 10rem;
      margin: 0; }
    @media only screen and (max-width: 37.5em) {
      .section-skills-text {
        margin-bottom: 3rem; } }

.section-project {
  background-color: #f4f4f4;
  padding-bottom: 6rem; }

.items {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.item {
  position: relative;
  height: 22rem;
  width: 35rem;
  background: #fc0000;
  background: black;
  overflow: hidden; }
  .item::after {
    content: '';
    position: absolute;
    display: block;
    background: inherit;
    opacity: 0.75;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
    transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
    -webkit-transition: -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1); }
  .item:hover:after {
    -webkit-transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
    transform: scale(2) translateX(0) translateY(0) rotate(-28deg); }
  .item:hover .item-image {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }
  .item:hover .item-text {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  .item-image {
    width: 100%;
    height: 100%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    display: block;
    -webkit-transition: -webkit-transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: -webkit-transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 750ms cubic-bezier(0.2, 1, 0.3, 1); }
    .item-image-1 {
      background: url("../Img/portfolio.png") no-repeat center center/cover; }
    .item-image-2 {
      background: url("../Img/converter.png") no-repeat center center/cover; }
    .item-image-3 {
      background: url("../Img/expedoor.jpg") no-repeat center center/cover; }
    .item-image-4 {
      background: url("../Img/freedomtours.jpg") no-repeat center center/cover; }
    .item-image-5 {
      background: url("../Img/carrara.jpg") no-repeat center center/cover; }
    .item-image-6 {
      background: url("../Img/exercise_tracker.jpg") no-repeat center center/cover; }
  .item-image:before {
    content: '';
    display: block;
    padding-top: 75%;
    overflow: hidden; }
  .item-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 0; }
  .item-text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    text-align: center;
    z-index: 1;
    color: #fff;
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
    -webkit-transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1), transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1), transform 500ms cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
    -webkit-transition-delay: 300ms;
    transition-delay: 300ms; }
  .item-text-wrap {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    width: 100%;
    height: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    .item-text-wrap p {
      font-size: 1.6rem;
      text-align: center;
      padding: 2rem 0;
      padding: 0 0.5rem; }
  .item-text-title {
    font-size: 2rem;
    padding: 0 1rem;
    margin: 5px 0 0 0; }
  .item-link-github {
    position: relative; }
    .item-link-github:hover::after {
      width: 100%;
      position: absolute;
      top: -3.5rem !important;
      left: 0 !important; }
  .item-link a {
    text-decoration: none;
    color: #fff;
    background: #8b0000;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-weight: bold;
    padding: 10px 30px;
    border: 2px solid #8b0000;
    -webkit-transition: opacity 500ms;
    transition: opacity 500ms;
    opacity: 0.8; }
  .item-link a:hover {
    opacity: 1; }

.section-about-grid {
  content: '';
  display: table;
  clear: both; }
  .section-about-grid .about-img {
    height: 100%;
    float: left;
    width: 50%;
    padding: 5rem;
    border-top: 2px solid transparent;
    opacity: 0; }
    .section-about-grid .about-img .show {
      -webkit-animation: slowIn 2s forwards;
      animation: slowIn 2s forwards; }
    @media only screen and (max-width: 43.75em) {
      .section-about-grid .about-img {
        display: block;
        width: 100%;
        padding: 1rem;
        text-align: center;
        position: static;
        padding: 1rem; } }
    .section-about-grid .about-img-img {
      position: relative;
      background: url("../Img/about_me.jpg") no-repeat center center/cover;
      margin: 0 auto;
      height: 25rem;
      width: 25rem;
      border-radius: 50%;
      -webkit-box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.3);
      box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.3); }
      @media only screen and (max-width: 43.75em) {
        .section-about-grid .about-img-img {
          height: 25rem; } }
  .section-about-grid .about-info {
    width: 50%;
    height: 100%;
    float: right;
    padding: 5rem;
    border-top: 2px solid transparent; }
    @media only screen and (max-width: 43.75em) {
      .section-about-grid .about-info {
        display: block;
        width: 100%;
        padding: 2rem 3rem; } }

@-webkit-keyframes slowIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slowIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.show-img {
  -webkit-animation: slowIn 2s forwards;
  animation: slowIn 2s forwards; }

.section-contact {
  background: url("../Img/contact-small.jpg") no-repeat center center/cover;
  min-height: -webkit-min-content;
  min-height: -moz-min-content;
  min-height: min-content; }
  @media only screen and (max-width: 75em) {
    .section-contact {
      max-height: -webkit-min-content;
      max-height: -moz-min-content;
      max-height: min-content; } }
  @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), (min-resolution: 192dpi) and (min-width: 37.5em), (min-width: 125em) {
    .section-contact {
      background: url("../Img/contact.jpg") no-repeat center center/cover; } }
  .section-contact-grid {
    width: 50%; }
    @media only screen and (max-width: 75em) {
      .section-contact-grid {
        display: block;
        max-width: 60rem;
        padding: 2rem; } }
    @media only screen and (max-width: 56.25em) {
      .section-contact-grid {
        display: block;
        width: 60rem;
        padding: 2rem; } }
    @media only screen and (max-width: 43.75em) {
      .section-contact-grid {
        margin: 0 auto; } }
    @media only screen and (max-width: 37.5em) {
      .section-contact-grid {
        width: 90%; } }

.contact-form .u-visually-hidden {
  visibility: hidden; }

.contact-form-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 1.5rem 0; }
  .contact-form-field-label {
    padding: 0.2rem;
    position: relative;
    bottom: 0;
    width: 15rem;
    text-align: center;
    color: #fff;
    background: #252323;
    margin-right: 3rem;
    cursor: pointer; }
    @media only screen and (max-width: 37.5em) {
      .contact-form-field-label {
        display: none; } }
  .contact-form-field-label::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    right: -15px;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent #252323; }
  .contact-form-field-input {
    font-family: inherit;
    font-size: 1.7rem;
    width: 100%;
    height: 100%;
    border: 0.3rem solid transparent;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    padding: 0.5rem; }
    .contact-form-field-input:focus {
      outline: none;
      border-bottom: 0.3rem solid #8b0000; }
    .contact-form-field-input::-webkit-input-placeholder {
      color: #999;
      opacity: 1;
      font-size: 1.7rem; }
    .contact-form-field-input:-ms-input-placeholder {
      color: #999;
      opacity: 1;
      font-size: 1.7rem; }
    .contact-form-field-input::-ms-input-placeholder {
      color: #999;
      opacity: 1;
      font-size: 1.7rem; }
    .contact-form-field-input::placeholder {
      color: #999;
      opacity: 1;
      font-size: 1.7rem; }
  .contact-form-field-submit {
    text-transform: uppercase;
    background-color: #252323;
    color: #fff;
    font-size: 2rem;
    padding: 1rem 4rem;
    cursor: pointer;
    margin-bottom: 3rem;
    outline-color: #252323;
    border-top: 3px solid transparent;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out; }
    .contact-form-field-submit:hover {
      color: #ccc;
      border-top: 3px solid #fc0000; }
  .contact-form-field textarea {
    font-size: 2rem; }

.footer {
  background-color: black;
  color: white; }
  .footer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 4rem 0; }
    @media only screen and (max-width: 37.5em) {
      .footer-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        padding: 1rem; } }
  @media only screen and (max-width: 75em) {
    .footer {
      padding: 0 3rem; } }

.success-page {
  height: 100vh; }

.section-success {
  width: 50vw;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0.5px 0.5px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0.5px 0.5px 15px rgba(0, 0, 0, 0.2);
  text-align: center; }
  .section-success h2 {
    color: #fc0000;
    font-size: 4rem; }
  .section-success a {
    margin: 3rem 0; }
  .section-success .social-icon {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .section-success .social-icon a {
      color: #fc0000; }
